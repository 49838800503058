









































































































































.first-container{
  height: 100%;
}

#main-container{
  height:100%;
  width:100%;
  overflow:hidden !important;
}

.iframe-container{
  height:100% !important;
}

.basic-iframe-container-main,
.basic-iframe-container,
.cell-container,
.advanced-exercises-container{
  height: 100% !important;
}

header{
  display:none !important;
}

.container-image{
  /* Aplica un margen automático para centrar el div horizontalmente */
  margin: 0 auto;
  /* Ajusta el ancho máximo del contenedor */
  max-width: 800px;
  max-height: auto;
  
}



.container-image img{
  max-width: 500px;   
  max-height: 800px;
  margin: 20px;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .container-image img {
    width: 500px;
  }
}

.text-center {
  text-align: center;
  margin: 20px;
}

.container-image p {    
  text-align: justify;
}

.login-button{
  color: white;
  background-color: #00add8;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  width: 150px;
  margin-top: 20px;
  font-size: 14px;
}
.login-button:hover{
  background-color: #00bdec;
}

.not-allowed-exercises-container{
  text-align: center;
  margin-top: 50px;
}

.link-to-store{
  color: #35add8 !important;
}

.not-allowed-exercises-title{
  color: #FF9B00;
  font-weight: bold;
}

/* Initially hide the main content but keep it rendered */
.basic-iframe-container-main,
.advanced-exercises-container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* When loading finishes, fade it in */
.loading-visible {
  opacity: 1;
}

/* Keep it hidden during loading */
.loading-hidden {
  opacity: 0;
}

